@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  line-height: 24px;
}

html {
  font-family: "Poppins", sans-serif;
}

h2 {
  line-height: 50px;
}

h3 {
  line-height: 40px;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba($color: #000, $alpha: 0.2);
}

::-webkit-scrollbar-track {
  background: rgba($color: #fff, $alpha: 0.5);
}

.container {
  width: 100%;
  max-width: 1440px;
  height: 100%;
  padding: 0 40px;
  margin: 0 auto;
}

.section-title {
  font-size: 2.2rem;
  font-weight: 600;
  letter-spacing: 1px;
  color: #323232;
}

.main-button {
  width: 230px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #fff;
  border: 1px solid #037ffd;
  background: #037ffd;
  transition: 0.3s;

  &:hover {
    color: #037ffd;
    background: #fff;
  }
}

@media (max-width: 500px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 450px) {
  .container {
    padding: 0 20px;
  }
}

@media (max-width: 380px) {
  .container {
    padding: 0 15px;
  }
}
